import { render, staticRenderFns } from "./PaginationDefaultLayout.vue?vue&type=template&id=7faf3205&scoped=true&"
import script from "./PaginationDefaultLayout.vue?vue&type=script&lang=js&"
export * from "./PaginationDefaultLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7faf3205",
  null
  
)

export default component.exports