<template>
    <div class="container-pagination">
        <div class="wrapper-pagination home-pagebar">
            <div class="inline-block" v-if="paginationData.total > 0">
                <el-button class="btn-page pink" :disabled="page === 1"
                           @click="changePage('page',page=1)">처음
                </el-button>
                <el-button class="btn-page" :disabled="page === 1"
                           @click="changePage('page',page-1)">이전
                </el-button>
                <el-pagination
                    :page-size="paginationData.size"
                    :current-page.sync="page"
                    :pager-count="11"
                    :total="paginationData.total"
                    @current-change="changePage('page', page)"
                    class="pagination-bar"
                    layout="pager"
                    background>
                </el-pagination>

                <el-button class="btn-page" :disabled="page === returnLastPage()"
                           @click="changePage('page',page+1)">다음
                </el-button>
                <el-button class="btn-page pink" :disabled="page === returnLastPage()"
                           @click="changePage('page',page=returnLastPage)">마지막
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaginationDefaultLayout",
    mixins: [],
    components: {},
    inject: ['setPage'],
    provide() {
        return {}
    },
    props: {
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
    },
    data() {
        return {
            page: 1,
            size: 10,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.page = this.paginationData.page;
        this.size = this.paginationData.size;
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changePage(key, value) {
            this.setPage(key, value);
        },
        returnLastPage() {
            return Math.ceil(this.paginationData.total / this.paginationData.size);
        },
    },
    watch: {
        'paginationData.page': {
            deep: true,
            handler: function (val, oldVal) {
                this.page = this.paginationData.page;
            }
        },
    },
};
</script>

<style scoped>

</style>